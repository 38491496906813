import { useTranslate } from '@pankod/refine-core'
import { Collapse, DateField, Form, Input, Modal, ModalProps, Row, Typography } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { DEFAULT_DATE_TIME_FORMAT, supabaseClient } from 'utility'

type CommentModalProps = {
  modalProps: ModalProps,
  close: () => void,
  orderId: number,
  comment: string,
  updateOrderValue: Function
}

export const CommentModal: React.FC<CommentModalProps> = ({ modalProps, close, orderId, comment, updateOrderValue }) => {
  const t = useTranslate()
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [historicData, setHistoricData] = useState<any>()
  const [currentKey, setCurrentKey] = useState<string | undefined>(undefined)

  const { Panel } = Collapse

  const getHistoryData = async () => {
    const { data, error } = await supabaseClient
      .from('audit_orders')
      .select('record->>comments, record->>updated_by, created_at')
      .eq('resource_id', orderId)
      .not('record->>comments', 'is', null)
      .order('created_at', { ascending: false })

    if (error) {
      throw new Error(error.message)
    }

    const refinedData: any[] = []
    const commentsArray: string[] = []
    data.forEach((i) => {
      if (!commentsArray.includes(i.comments)) {
        commentsArray.push(i.comments)
        refinedData.push(i)
      }
    })

    setHistoricData(refinedData)
  }

  useEffect(() => {
    getHistoryData()
  }, [])

  const { TextArea } = Input

  const handleOk = async () => {
    setLoading(true)
    await supabaseClient
      .from('orders')
      .update({
        comments: (text === '') ? null : text,
        updated_by: supabaseClient.auth.user()?.email
      })
      .eq('id', orderId)

    setText('')
    if (updateOrderValue) {
      updateOrderValue(text)
    }
    getHistoryData()
    setLoading(false)
    close()
  }

  useEffect(() => {
    if (historicData?.find((historic: any) => historic.comments !== null)) {
      setCurrentKey('history')
    } else {
      setCurrentKey(undefined)
    }
  }, [historicData])

  const addLineBreak = (text: string) => {
    const fragments = text.split('\n')
    return fragments.map((fragment) => <>{fragment}<br/></>)
  }

  return (
    <Modal
      {...modalProps}
      title={t('order.actions.writeComment')}
      centered
      width={'50%'}
      afterClose={() => {
        setText('')
      }}
      onOk={() => {
        handleOk()
        setCurrentKey(undefined)
      }}
      onCancel={() => {
        close()
        setCurrentKey(undefined)
      }}
      okButtonProps={{
        loading: loading,
        disabled: (text === '')
      }}
    >
      <Collapse onChange={() => {
        currentKey === undefined ? setCurrentKey('history') : setCurrentKey(undefined)
      }} activeKey={currentKey} style={{ marginBottom: '5px' }} bordered={false} ghost={false}>
        <Panel key={'history'} header={'Historial'}>
          <div style={{ height: '200px', overflowY: 'scroll' }}>
            {historicData?.map((historic: any, index: number) => (
              historic.comments === null
                ? null
                : <div key={index}>
                  <Row style={{ width: '90%', marginLeft: '5px', marginTop: '0.5rem' }} gutter={[10, 0]} wrap>
                      <DateField value={historic.created_at} format={DEFAULT_DATE_TIME_FORMAT} />
                      <Typography style={{ marginLeft: '10px', marginRight: '10px' }}>-</Typography>
                      <Typography>{historic.updated_by}</Typography>
                      <Typography style={{ marginLeft: '10px', marginRight: '10px' }}>-</Typography>
                      <Typography style={{ fontWeight: 'bold' }}>{addLineBreak(historic.comments)}</Typography>
                  </Row>
                </div>
            ))}
          </div>
        </Panel>
      </Collapse>
      <Form layout='vertical'>
        <Form.Item name='comments'>
          <TextArea autoSize={{ minRows: 6, maxRows: 30 }} onChange={(e) => {
            if (e.target.value !== text) setText(e.target.value)
          }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
